.start__dive {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ffffff;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    color: #000000;
    cursor: pointer;
    pointer-events: all;
    transition: all ease .3s;
    z-index: 101;
    &:hover {
        box-shadow: 0 0 20px #fff; } }

.dive__timer {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10vh;
    pointer-events: none;
    opacity: 0;
    transition: all ease 3s; }

.home__screen {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 2s; }

.my__name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100vw;
    pointer-events: none;
    * {
        position: absolute; }
    h1 {
        font-weight: bolder;
        text-transform: lowercase;
        letter-spacing: 4px;
        font-size: 20px; }
    h2 {
        font-size: 20vw;
        font-weight: bolder;
        opacity: .1;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        transform: translateY(-20%); }
    .devdes {
        width: 100vw;
        height: 100px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        h3 {
            font-weight: bolder;
            opacity: 0.4;
            font-size: 18px;
            margin: 0 20px;
            position: relative; } } }

.home__menu {
    position: absolute;
    bottom: 25%;
    display: flex;
    max-width: 100vw;
    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 40px;
        height: 30px;
        width: 120px;
        border: 1px solid #fff;
        opacity: 0.3;
        border-radius: 6px;
        transition: all ease .3s;
        font-size: 12px;
        &:hover {
            opacity: 1;
            border: 1px solid #00e1ff; }
        &:nth-child(2) {
            opacity: 1;
            background-color: #ff6c6c54;
            color: #fff;
            &:hover {
                background-color: #ff6c6c;
                opacity: 1; } } } }

.about__text {
    position: absolute;
    top: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    color: #fff;
    transition: all ease .3s;
    text-align: center;
    pointer-events: none;
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        padding: 18px;
        line-height: 26px;
        letter-spacing: 1.2px;
        font-style: italic; } }

.portfolio__inner {
    // background-color: #000
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .portfolio__inner--img {
        width: 100vw; }
    &:nth-child(odd) {
        .portfolio__inner--img {
            transform: rotateZ(45deg); } }
    &:nth-child(even) {
        .portfolio__inner--img {
            transform: rotateZ(-45deg); } }
    .portfolio__inner--text {
        position: absolute;
        font-weight: bolder;
        letter-spacing: 1.4px;
        font-style: italic; }
    img {
        width: 100%; } }
